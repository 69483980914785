export default ({
    check: (state, formRefs, translations) => {
        // check, validate
        const textErrors = translations.errors
        const errors = []
        const data = {
            zonaBusqueda:         state.pickupOffice.city,      // 'Aeropuertos'
            depDate:              `${state.pickupDate.day}/${state.pickupDate.month}/${state.pickupDate.year}`, //  state.pickupDate 27/10/2020 0:00:00
            horaSalida:           state.pickupTime,   //"10:00"
            oficinaSalida:        state.pickupOffice.idZone, // "31909"
            oficinaSalidaTXT:     state.pickupOffice.name, // "Aeropouerto sur"
            otherHotelNamePickup: state.pickupOffice.name, //  "Aeropuerto sur"
            pickupCityId:         state.pickupOffice.idCity, //  "31902"
            pickupCountryId:      state.pickupOffice.pickupCountryId,  //  "1317"
        
            zonaBusquedaReturn :  state.dropoffOffice.city,       // 'Aeropuertos'
            retDate:              `${state.dropoffDate.day}/${state.dropoffDate.month}/${state.dropoffDate.year}`,  //03/11/2020 0:00:00
            horaRetorno:          state.dropoffTime,              // "10:00"
            dropoffCityId:        state.dropoffOffice.idCity,     // "31902"
            oficinaRetorno:       state.dropoffOffice.idZone,         // "31909"
            oficinaRetornoTXT:    state.dropoffOffice.name,       // "Aeropuerto sur"
            otherHotelNameDropoff: state.dropoffOffice.name,      //  "Aeropuerto sur"
        
            driverDateOdBirth:    `01/01/0001`,
            hasMoreThan24Years:   !state.isYoungDriver,
            language:             translations.language
        }

        if (data.oficinaSalida === "-1")
        {
            errors.push(textErrors.selectedPickUpOffice.message);
        }
        if (!data.oficinaSalida || data.oficinaSalida.lenght < 1) errors.push(textErrors.missPickupLocation.message)
        if (!data.oficinaRetorno || data.oficinaRetorno.lenght < 1) errors.push(textErrors.missDropoffLocation.message)
        if (new Date(state.pickupDate.year, state.pickupDate.month, state.pickupDate.day) < getTomorrow()) errors.push(textErrors.missPickupDate.message)

        console.log("PickupDate->");
        console.log("Año ->" + state.pickupDate.year);
        console.log("Mes ->" + state.pickupDate.month);
        console.log("Dia ->" + state.pickupDate.day);


        console.log("DropoffDate->");
        console.log("Año ->" + state.dropoffDate.year);
        console.log("Mes ->" + state.dropoffDate.month);
        console.log("Dia ->" + state.dropoffDate.day);

        console.log("formated PickupDate-> " + new Date(parseInt(state.pickupDate.year), parseInt(state.pickupDate.month), parseInt(state.pickupDate.day)));
        console.log("formated DropOffDate-> " + new Date(parseInt(state.dropoffDate.year), parseInt(state.dropoffDate.month), parseInt(state.dropoffDate.day)));

        let _pickupYear = parseInt(state.pickupDate.year);
        let _pickupMonth = parseInt(state.pickupDate.month);
        let _pickupDay = parseInt(state.pickupDate.day);

        let _dropoffYear = parseInt(state.dropoffDate.year);
        let _dropoffMonth = parseInt(state.dropoffDate.month);
        let _dropoffDay = parseInt(state.dropoffDate.day);

        if (_dropoffYear < _pickupYear)
        {
            errors.push(textErrors.missDropoffDate.message);
        }

        if (_dropoffYear === _pickupYear && _dropoffMonth === _pickupMonth && _dropoffDay < _pickupDay)
        {
            errors.push(textErrors.missDropoffDate.message);
        }
        
        if (_dropoffYear === _pickupYear && _dropoffMonth < _pickupMonth)
        {
            errors.push(textErrors.missDropoffDate.message);
        }


        // if (new Date(state.pickupDate.year, state.pickupDate.month, state.pickupDate.day) >= new Date(state.dropoffDate.year, state.dropoffDate.month, state.dropoffDate.day)) errors.push(textErrors.missDropoffDate.message)        

        if(errors.length < 1){
                formRefs.ZonaBusqueda.current.value           = encodeURI(data.zonaBusqueda)
                formRefs.DepDate.current.value                = data.depDate
                formRefs.HoraSalida.current.value             = data.horaSalida
                formRefs.OficinaSalida.current.value          = data.oficinaSalida
                formRefs.OficinaSalidaTXT.current.value       = encodeURI(data.oficinaSalidaTXT)
                formRefs.otherHotelNamePickup.current.value   = data.otherHotelNamePickup
                formRefs.PickupCityId.current.value           = data.pickupCityId
                formRefs.PickupCountryId.current.value        = data.pickupCountryId
                formRefs.ZonaBusquedaReturn.current.value     = encodeURI(data.zonaBusquedaReturn)
                formRefs.RetDate.current.value                = data.retDate
                formRefs.HoraRetorno.current.value            = data.horaRetorno
                formRefs.DropoffCityId.current.value          = data.dropoffCityId
                formRefs.OficinaRetorno.current.value         = data.oficinaRetorno
                formRefs.OficinaRetornoTXT.current.value      = encodeURI(data.oficinaRetornoTXT)
                formRefs.OtherHotelNameDropoff.current.value  = data.otherHotelNameDropoff
                formRefs.DriverDateOdBirth.current.value      = data.driverDateOdBirth
                formRefs.HasMoreThan24Years.current.value     = data.hasMoreThan24Years
                formRefs.Language.current.value               = data.language
        }

        return errors
    }
})


const getTomorrow = () => {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    tomorrow.setHours(0,0,0,0)
    return tomorrow
}