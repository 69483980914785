import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Icon, {icons} from './Icons'
import '../css/DriverAge.scss'
import { inputTypes } from '../const'

export default props => {
    const {
        isYoungDriver,
        style,
        forceClose,
        setActiveInput,
        inputType,
        onChange,
        textRegularDriver,
        textYoungDriver,
        DriverLeyend
    } = props


    const [isOpen, setIsOpen] = useState(false)

    if(isOpen && forceClose) setIsOpen(false)

    const handleClick = yd => {
        setIsOpen(!isOpen)        
        setActiveInput(isOpen ? inputTypes.none : inputType)
    }


    return (
        <div className="driver-age-container" style={style}>
            <div className="selected-age" onClick={handleClick}>
                <Icon icon={icons.driver} className='icon-input' />
                <div className='driver-age-value' >{isYoungDriver ? textYoungDriver : textRegularDriver}</div>
                <FontAwesomeIcon icon='chevron-down' className='icon-input' />
            </div>                        
            
            {isOpen && 
             <div className="selection-ages" >
                 <div className={classNames('selecion-ages-item', isYoungDriver ? 'selected' : '')} onClick={() => onChange(true)}>
                     {textYoungDriver}
                 </div>
                 <div className={classNames('selecion-ages-item', !isYoungDriver ? 'selected' : '')} onClick={() => onChange(false)}>
                     {textRegularDriver}
                 </div>
             </div> 
            }
        </div>
    )
}