
const hasTextValue = values => {
    if(typeof values !== 'object' || values.length < 1) 
        return false

    for(let i in values)
        if(typeof  values[i] === 'undefined' || values[i] === '')
            return false

    return true
}

const tomorrow = new Date()
const nextWeek = new Date()

tomorrow.setDate(tomorrow.getDate() + 1)
nextWeek.setDate(tomorrow.getDate() + 7)

const defaultPickupDate = {
  day: tomorrow.getDate(),
  month: tomorrow.getMonth() + 1,
  year: tomorrow.getFullYear()
}


const defaultDropoffDate = {
    day: nextWeek.getDate(),
    month: nextWeek.getMonth() + 1,
    year: nextWeek.getFullYear()
  }
  
const defaultTime= '10:00'

export default defaultParams => {
    const {
        pickupLocation,
        pickupLocationName,
        pickupCityId,
        pickupCountryId,
        pickupDate,
        pickupTime,
        days,
        dropoffLocation,
        dropoffLocationName,
        dropoffCityId,
        dropoffDate,
        dropoffTime,
        driverAge,
        zonaBusquedaPickup,
        zonaBusquedaDropoff,
        tenerifeMoreThan24Years,

        modelo,
        onSearchSendExternalLink,
        affiliateId,
        isWhiteLabel,        
    } = defaultParams




    let status = {
        pickupOffice:   {},
        pickupDate:     defaultPickupDate,
        pickupTime:     defaultTime,
        dropoffOffice:  {},
        dropoffDate:    defaultDropoffDate,
        dropoffTime:    defaultTime,
        driverAge:      30,
        minDriverAge:   25,
        isYoungDriver:  false,
        days:           1
      }

    if(hasTextValue([pickupLocation, zonaBusquedaPickup, pickupLocationName, pickupCityId, pickupCountryId]))
        status.pickupOffice = {
                idZone:     pickupLocation,
                city:       zonaBusquedaPickup,
                idCity:     pickupCityId,
                name:       pickupLocationName,
                pickupCountryId:  pickupCountryId
        }

    if(hasTextValue([dropoffLocation, zonaBusquedaDropoff, dropoffLocationName, dropoffCityId]))
        status.dropoffOffice = {
                idZone:     dropoffLocation,
                city:       zonaBusquedaDropoff,
                idCity:     dropoffCityId,
                name:       dropoffLocationName
        }

    if(hasTextValue([pickupDate]))
        status.pickupDate = pickupDate

    if(hasTextValue([pickupTime]))
        status.pickupTime = pickupTime

    if(hasTextValue([dropoffDate]))
        status.dropoffDate = dropoffDate

    if(hasTextValue([dropoffTime]))
        status.dropoffTime = dropoffTime

    if(hasTextValue([driverAge]))
        status.driverAge = driverAge

    if(hasTextValue([days]))
       status.days = days
        
    status.isYoungDriver = tenerifeMoreThan24Years === 'false'

    return status
}